import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CLocalNav,
  CHeroImg,
  CUniqueMedia,
  CFaqList,
  CSectTitle,
  CBtnList,
  CAccessMedia,
  CContactBox,
  LPlan,
} from '../../components/_index';
import { setLang } from '../../utils/set-lang';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const lang = setLang();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'attraction',
            ja: 'ホテルの魅力',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'ホテルの魅力',
            },
          }}
        />
      </CJumbotron>
      {/* <CLocalNav
        data={[
          {
            img: {
              src: '/assets/images/about/img_localnav.png',
              alt: 'よくあるご質問',
            },
            link: {
              href: '/about#faq',
              blank: false,
            },
            title: {
              ja: 'よくあるご質問',
            },
          },
          {
            img: {
              src: '/assets/images/about/img_localnav02.png',
              alt: 'アクセス',
            },
            link: {
              href: '/about#access',
              blank: false,
            },
            title: {
              ja: 'アクセス',
            },
          },
        ]}
      /> */}
      <div className="">
        <LWrap>
          <section className="u_mbExLarge">
            <h2 className="c_headingLv2 u_mtExLarge">
              <small>すぐそこにあるリゾートで</small>
              <br />
              非日常のOMOTENASHIを
              <br className="u_sp" />
              「心ゆくまで、たのしむ」
            </h2>
            <p className="c_sectLead u_mb30">
              仙台駅から車で約30分とは思えない心が落ち着く自然溢れる美しい景色と
              <br className="u_pc" />
              ヨーロッパの趣を感じる体がほぐれる優雅で快適な空間。
            </p>
            <p className="c_sectLead">
              杜の都を訪れる要人を迎えてきたOMOTENASHIを思う存分おたのしみください。
            </p>
            <CHeroImg
              img={{
                src: '/assets/images/about/img_about.png',
                alt: '',
              }}
            />
          </section>
          <CUniqueMedia
            data={[
              {
                img: {
                  src: '/assets/images/about/img_media.png',
                  alt: '',
                },
                title: 'EASY ACCESS',
                subTitle: (
                  <>
                    県内外から簡便なアクセス
                    <br />
                    気軽にたのしめるリゾートステイ
                  </>
                ),
                text: (
                  <>
                    関東・首都圏や東北各県からアクセスしやすい仙台。
                    <br className="u_pc" />
                    仙台駅からは勿論、東北道のICから近く
                    <br className="u_pc" />
                    お車でもアクセスしやすい仙台リゾート。
                    <br />
                    すぐそこにある非日常空間で上質なリゾートステイをご満喫ください。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/img_media02.png',
                  alt: '',
                },
                title: 'ONLY PLACE',
                subTitle: (
                  <>
                    ヨーロッパの趣を感じる非日常
                    <br />
                    自然の中でたのしむ優雅な時間
                  </>
                ),
                text: (
                  <>
                    お城のような佇まいと英国式の庭園が非日常に誘う。
                    <br className="u_pc" />
                    開放的なテラスや自然に囲まれたガーデン、
                    <br className="u_pc" />
                    在仙デザイナーが手掛けたクリエイティブな空間で
                    <br className="u_pc" />
                    美しい景色と美味しい料理をたのしむ贅沢な時間をご堪能ください。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/img_media03.png',
                  alt: '',
                },
                title: 'TASTY CUISINE',
                subTitle: (
                  <>
                    多くの要人をもてなしてきた実績
                    <br />
                    珠玉の料理をたのしむ至福のひととき
                  </>
                ),
                text: (
                  <>
                    要人を虜にするホテルの自慢の料理を気軽に味わえる。
                    <br className="u_pc" />
                    様々なシーンを演出してきた料理を
                    <br className="u_pc" />
                    心配りが行き届いたOMOTENASHIと共にまた食べたくなる味を
                    <br className="u_pc" />
                    お時間の許す限りごゆるりとおたのしみください。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/img_media04.png',
                  alt: '',
                },
                title: 'ENJOY EVENT',
                subTitle: (
                  <>
                    心と身体を癒やして創る健康美
                    <br />
                    季節をたのしむ充実のホテルライフ
                  </>
                ),
                text: (
                  <>
                    行き届いたホテルのサービスで始めるウェルネス。
                    <br />
                    ヨガやエステをはじめ宙ネタリウムやサイクリングなど
                    <br />
                    四季折々の自然の中で心安らぐ癒やし体験を
                    <br />
                    自分のリズムにあわせゆったりおたのしみください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </div>

      <section id="faq" className="l_sect04">
        <LWrap>
          <CSectTitle
            title={{
              ja: 'よくあるご質問',
              en: <>FAQ</>,
            }}
          />
          <CFaqList
            hash=""
            data={[
              {
                q: 'チェックイン・チェックアウトは何時ですか?',
                a: (
                  <>
                    チェックインは15:00から、チェックアウトは11:00まででございます。（プランにより異なる場合がございます）
                  </>
                ),
              },
              {
                q: '喫煙ルームはありますか?',
                a: (
                  <>
                    喫煙ルームはございません。ロビーの喫煙所をご利用ください。
                  </>
                ),
              },
              {
                q: '子供料金はありますか?',
                a: (
                  <>
                    未就学のお子様がベッドを使用しない（添い寝）場合、宿泊料金は基本かかりません。（※ただし、お食事をご希望の場合は別途申し受けます。）なお、小学生以上のお子様については、大人と同額の宿泊料金がかかります。
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: 'よくあるご質問',
                link: {
                  href: '/faq/',
                },
                color: 'borderBlack',
              },
            ]}
          />
        </LWrap>
      </section>
      <section id="access" className="l_sect04 u_pt0">
        <LWrap>
          <CSectTitle title={{ ja: 'アクセス', en: 'ACCESS' }} />
          <CAccessMedia
            data={[
              {
                text: (
                  <>
                    <p>
                      〒981-3204
                      <br />
                      仙台市泉区寺岡6-2-1
                    </p>
                    <p>
                      <a href="TEL:0223771111">TEL:022-377-1111（代表）</a>
                    </p>
                  </>
                ),
                btnList: [
                  {
                    label: 'アクセス',
                    link: {
                      href: '/access/',
                    },
                    icon: 'arrow',
                    color: 'borderTheme',
                  },
                  {
                    label: 'Google MAP',
                    link: {
                      href: 'https://www.google.com/maps?ll=38.342863,140.837966&z=16&t=m&hl=ja&gl=JP&mapclient=embed&cid=12978498071859459586',
                      blank: true,
                    },
                    icon: 'arrow',
                    color: 'borderTheme',
                  },
                ],
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: 'お問合せ',
              en: <>CONTACT</>,
            }}
          />
          <p className="c_sectLead u_mb30">
            ご宿泊の空き状況の検索・ご予約・お問合せは以下より承ります。
          </p>
          <CContactBox
            color="white"
            tel={{
              number: '022-377-1111',
              note: <>受付時間 10:00〜18:00</>,
            }}
            mail={{
              link: {
                href: 'https://contact.royalparkhotels.co.jp/srph/contact/',
              },
            }}
          />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
